import Web3Container from "../components/Web3Container/Web3Container";
import { toast } from "react-toastify";

import AppConfig from "../AppConfig";

function updateContractBalance() {
  return async dispatch => {
    let web3 = Web3Container.get();
    let accounts = await web3.getAccounts();
    let balanceBigNumber = await web3.contract.methods
      .getBalanceByAddress(accounts[0])
      .call();
    let contractBalance = parseFloat(
      web3.web3Instance.utils.fromWei(balanceBigNumber.toString())
    ).toFixed(4);
    dispatch({
      type: "UPDATE_CONTRACT_BALANCE",
      payload: {
        contractBalance,
        contractBalanceWei: balanceBigNumber.toString()
      }
    });
  };
}

function updateWalletBalance() {
  return async dispatch => {
    let web3 = Web3Container.get();
    let accounts = await web3.getAccounts();
    let walletBalanceWei = await web3.web3Instance.eth.getBalance(accounts[0]);
    let walletBalance = parseFloat(
      web3.web3Instance.utils.fromWei(walletBalanceWei)
    ).toFixed(4);
    dispatch({
      type: "UPDATE_WALLET_BALANCE",
      payload: { walletBalance, walletBalanceWei }
    });
  };
}

function increasePendingTransactions() {
  return async dispatch => {
    dispatch({
      type: "PENDING_TRANSACTIONS",
      payload: true
    });
  };
}

function decreasePendingTransactions() {
  return async dispatch => {
    dispatch({
      type: "PENDING_TRANSACTIONS",
      payload: false
    });
  };
}

function setWeb3ContainerReady(isReady) {
  return async dispatch => {
    dispatch({ type: "IS_WEB3_CONTAINER_READY", payload: isReady });
  };
}

function checkWeb3ContainerReady() {
  return async dispatch => {
    let web3 = Web3Container.get();
    let isContainerReady =
      web3 &&
      web3.web3Instance &&
      (await web3.web3Instance.eth.net.isListening()) &&
      web3.getAccounts &&
      (await web3.getAccounts()).length > 0 &&
      (await web3.contract.methods.owner().call());
    dispatch({
      type: "IS_WEB3_CONTAINER_READY",
      payload: isContainerReady
    });
  };
}

function refreshBalances() {
  return async dispatch => {
    dispatch(increasePendingTransactions());
    dispatch(updateWalletBalance());
    dispatch(updateContractBalance());
    dispatch(getContractEvents());
    dispatch(decreasePendingTransactions());
  };
}

function depositFromContract(amountInEther, walletBalanceWei) {
  return async dispatch => {
    let web3 = Web3Container.get();
    let accounts = await web3.getAccounts();
    dispatch(increasePendingTransactions());
    let amountInWei = web3.web3Instance.utils.toWei(
      `${amountInEther}`,
      "ether"
    );
    amountInWei =
      Number(walletBalanceWei) < Number(amountInWei)
        ? walletBalanceWei
        : amountInWei;

    let answer;
    try {
      answer = await web3.contract.methods.deposit().send({
        from: accounts[0],
        value: amountInWei
      });
      console.log("depositFromContract successfull", answer);
      toast.success(`${amountInEther} Ether was successfully deposited!`);
    } catch (err) {
      toast.error(err && err.message ? err.message : "Error!");
      console.log("depositFromContract failed", err);
    }
    dispatch(decreasePendingTransactions());
  };
}

function withdrawFromContract(amountInEther, contractBalanceWei) {
  return async dispatch => {
    let web3 = Web3Container.get();
    let accounts = await web3.getAccounts();
    dispatch(increasePendingTransactions());
    let amountInWei = web3.web3Instance.utils.toWei(
      `${amountInEther}`,
      "ether"
    );
    amountInWei =
      Number(contractBalanceWei) < Number(amountInWei)
        ? contractBalanceWei
        : amountInWei;
    let answer;
    try {
      answer = await web3.contract.methods.withdraw(amountInWei).send({
        from: accounts[0]
      });
      console.log("withdrawFromContract successfull", answer);
      toast.success(`${amountInEther} Ether was successfully withdrawn!`);
    } catch (err) {
      toast.error(err && err.message ? err.message : "Error!");
      console.log("withdrawFromContract failed", err);
    }
    dispatch(decreasePendingTransactions());
  };
}

function betOnContract(stakeInEther, spinUnder) {
  return async dispatch => {
    let web3 = Web3Container.get();
    let accounts = await web3.getAccounts();
    dispatch(increasePendingTransactions());
    let stakeInWei = web3.web3Instance.utils.toWei(`${stakeInEther}`, "ether");
    let answer;
    try {
      answer = await web3.contract.methods.bet(stakeInWei, spinUnder).send({
        from: accounts[0]
      });
      console.log("betOnContract was successfull", answer);
      toast.success(`Your bet was sucessfully registered!`);
    } catch (err) {
      toast.error(err && err.message ? err.message : "Error!");
      console.log("betOnContract failed", err);
      dispatch(decreasePendingTransactions());
    }
  };
}

async function getBlock(transactionHash) {
  let web3 = Web3Container.get();
  return web3.web3Instance.eth.getBlock(transactionHash);
}

function getContractEvents(from) {
  const EVENT_NAME = "allEvents";
  const BLOCK_RANGE = 8464380;
  return async dispatch => {
    let web3 = Web3Container.get();
    let lastLocallyKnownBlock = await web3.web3Instance.eth.getBlockNumber();
    let fromBlock = from || lastLocallyKnownBlock - BLOCK_RANGE;
    let logs = await web3.contract.getPastEvents(EVENT_NAME, {
      fromBlock,
      toBlock: "latest"
    });
    for (let log of logs) {
      log.blockData = await getBlock(log.blockNumber);
    }
    dispatch({
      type: "UPDATE_EVENT_LIST",
      payload: {
        fromBlock: fromBlock,
        toBlock: "latest",
        eventList: logs
      }
    });
  };
}

function isGetStakePayoutInputValid(stakeInEther, spinUnder) {
  if (stakeInEther && spinUnder) {
    let stakeInEtherNumber = parseFloat(stakeInEther);
    let spinUnderNumber = parseInt(spinUnder);
    return (
      stakeInEtherNumber >= AppConfig.CONTRACT_SETTINGS.MIN_BET &&
      stakeInEtherNumber <= AppConfig.CONTRACT_SETTINGS.MAX_BET &&
      spinUnderNumber >= AppConfig.CONTRACT_SETTINGS.SPIN_MIN &&
      spinUnderNumber <= AppConfig.CONTRACT_SETTINGS.SPIN_MAX
    );
  }
  return false;
}

function getStakePayout(stakeInEther, spinUnder) {
  if (!isGetStakePayoutInputValid(stakeInEther, spinUnder)) {
    return async dispatch => {
      dispatch({
        type: "SET_PRIZE_AND_PAYOUT",
        payload: {
          prize: null,
          payout: null,
          stakeInEther,
          spinUnder
        }
      });
    };
  } else {
    return async dispatch => {
      let web3 = Web3Container.get();
      spinUnder = typeof spinUnder === "number" ? `${spinUnder}` : spinUnder;
      let stakeInWei = web3.web3Instance.utils.toWei(
        `${stakeInEther}`,
        "ether"
      );
      let prizeAndPayout = await web3.contract.methods
        .getPrizeAndPayout(`${stakeInWei}`, `${spinUnder}`)
        .call();
      let payout = parseFloat(prizeAndPayout[0].toString()) / 100;
      let prize = parseFloat(
        web3.web3Instance.utils.fromWei(prizeAndPayout[1].toString())
      ).toFixed(4);
      dispatch({
        type: "SET_PRIZE_AND_PAYOUT",
        payload: {
          prize,
          payout,
          stakeInEther,
          spinUnder
        }
      });
    };
  }
}

export default {
  refreshBalances,
  setWeb3ContainerReady,
  checkWeb3ContainerReady,
  updateContractBalance,
  updateWalletBalance,
  depositFromContract,
  withdrawFromContract,
  increasePendingTransactions,
  decreasePendingTransactions,
  betOnContract,
  getContractEvents,
  getStakePayout
};
