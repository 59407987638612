import React, { Fragment } from "react";
import { FaEthereum } from "react-icons/fa";

import AppConfig from "../../AppConfig";
export default class DepositWithdrawContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 1
    };
    this.amountChanged = this.amountChanged.bind(this);
    this.deposit = this.deposit.bind(this);
    this.withdraw = this.withdraw.bind(this);
  }

  componentDidMount() {
    this.props.refreshBalances();
    setInterval(this.props.refreshBalances, AppConfig.REFRESH_TIMER_MS * 2);
  }

  amountChanged(event) {
    this.setState({ amount: event.target.value });
  }

  deposit() {
    this.props.depositFromContract(
      this.state.amount,
      this.props.walletBalanceWei
    );
  }

  withdraw() {
    this.props.withdrawFromContract(
      this.state.amount,
      this.props.contractBalanceWei
    );
  }

  renderWalletBalanceOutput() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            Wallet <FaEthereum />
          </span>
        </div>
        <input
          type="text"
          name="walletBalance"
          className="form-control"
          disabled={true}
          value={this.props.walletBalance}
        />
      </div>
    );
  }

  renderContractBalanceOutput() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            Contract <FaEthereum />
          </span>
        </div>
        <input
          type="text"
          name="contractBalance"
          className="form-control"
          disabled={true}
          value={this.props.contractBalance}
        />
      </div>
    );
  }

  renderBalances() {
    return (
      <Fragment>
        <div className="col-6 text-center">
          {this.renderWalletBalanceOutput()}
        </div>
        <div className="col-6 text-center">
          {this.renderContractBalanceOutput()}
        </div>
      </Fragment>
    );
  }

  renderDepositButton() {
    return (
      <button
        style={{ width: "100%" }}
        className="btn btn-lg btn-primary"
        type="button"
        onClick={this.deposit}
        disabled={
          this.state.amount < 0.1 ||
          this.state.amount > Number(this.props.walletBalance)
        }
      >
        Deposit <FaEthereum />
      </button>
    );
  }

  renderWithdrawButton() {
    return (
      <button
        style={{ width: "100%" }}
        className="btn btn-lg btn-primary"
        type="button"
        onClick={this.withdraw}
        disabled={
          this.state.amount <= 0 ||
          this.state.amount > Number(this.props.contractBalance)
        }
      >
        Withdraw <FaEthereum />
      </button>
    );
  }

  renderAmountInput() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            Amount <FaEthereum />
          </span>
        </div>
        <input
          className="form-control"
          type="number"
          name="deposit"
          min="0.1"
          step="0.1"
          value={this.state.amount}
          onChange={this.amountChanged}
        />
      </div>
    );
  }

  renderInnerComponent() {
    return (
      <Fragment>
        <div className="row">
          <div className="col">{this.renderAmountInput()}</div>
        </div>
        <div className="row mt-2">
          <div className="col-6">{this.renderDepositButton()}</div>
          <div className="col-6">{this.renderWithdrawButton()}</div>
        </div>
        <div className="row mt-2">{this.renderBalances()}</div>
      </Fragment>
    );
  }

  render() {
    return <div className="col-12 col-lg-6">{this.renderInnerComponent()}</div>;
  }
}
