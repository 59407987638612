import React, { Fragment } from "react";
import { FaEthereum } from "react-icons/fa";
import moment from "moment";

import AppConfig from "../../AppConfig";
import Web3Container from "../Web3Container/Web3Container";

export default class ContractFeed extends React.Component {
  MAX_LIST_SIZE = 9;
  AppConfig = AppConfig;
  Web3Container = Web3Container;

  constructor(props) {
    super(props);
    this.convertBigNumberToEther = this.convertBigNumberToEther.bind(this);
    this.renderEventList = this.renderEventList.bind(this);
    this.renderEventListEntry = this.renderEventListEntry.bind(this);
  }

  componentDidMount() {
    this.props.getContractEvents();
  }

  convertBigNumberToEther(amountBigNumber) {
    let web3 = Web3Container.get();
    return web3.web3Instance.utils.fromWei(amountBigNumber.toString(), "ether");
  }

  renderHumanReadableDate(timestamp) {
    return moment.duration(moment().diff(moment.unix(timestamp))).humanize();
  }

  renderTransactionHash(hash) {
    return `${hash.substring(0, 5)}...${hash.substring(58, hash.length)}`;
  }

  renderWalletAddress(address) {
    return `${address.substring(0, 5)}...${address.substring(
      36,
      address.length
    )}`;
  }

  renderEventListEntry(entry) {
    let txLink = new URL(
      `tx/${entry.transactionHash}`,
      this.AppConfig.ETHERSCAN_ADDRESS
    );
    let addressLink = new URL(
      `address/${entry.returnValues._from}`,
      this.AppConfig.ETHERSCAN_ADDRESS
    );
    let isWin =
      Number(entry.returnValues._spin.toString()) <
      Number(entry.returnValues._spinUnder.toString());

    return (
      <tr
        key={entry.transactionHash}
        className={isWin ? "table-success" : "table-danger"}
      >
        <td className="d-none d-lg-table-cell d-xl-table-cell">
          <a href={txLink}>
            {this.renderTransactionHash(entry.transactionHash)}
          </a>
        </td>
        <td>
          <a href={addressLink}>
            {this.renderWalletAddress(entry.returnValues._from)}
          </a>
        </td>
        <td className="d-none d-sm-table-cell">
          {this.convertBigNumberToEther(entry.returnValues._stake)}
          <FaEthereum />
        </td>
        <td>
          1-
          {Number(entry.returnValues._spinUnder.toString()) - 1}
        </td>
        <td>{entry.returnValues._spin.toString()}</td>
        <td>
          {this.convertBigNumberToEther(entry.returnValues._prize)}
          <FaEthereum />
        </td>
        <td className="d-none d-sm-table-cell">
          {this.renderHumanReadableDate(entry.blockData.timestamp)}
        </td>
      </tr>
    );
  }

  renderEventList(eventList) {
    let listContent = [];
    for (let entry of eventList) {
      listContent.push(this.renderEventListEntry(entry));
    }
    if (listContent.length === 0) {
      return (
        <div className="row">
          <div className="col-12 d-flex flex-row justify-content-center mt-2">
            <h3>Nothing here yet...</h3>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-responsive">
          <table className="table text-center">
            <thead className="thead-dark">
              <tr>
                <th
                  scope="col"
                  className="d-none d-lg-table-cell d-xl-table-cell"
                >
                  Transaction
                </th>
                <th scope="col">From</th>
                <th scope="col" className="d-none d-sm-table-cell">
                  Stake
                </th>
                <th scope="col">Bet</th>
                <th scope="col">Spin</th>
                <th scope="col">Prize</th>
                <th scope="col" className="d-none d-sm-table-cell">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>{listContent}</tbody>
          </table>
        </div>
      );
    }
  }

  isEventList() {
    return (
      this.props.contractEvents &&
      Array.isArray(this.props.contractEvents.eventList)
    );
  }

  renderEmptyList() {
    return (
      <Fragment>
        <div className="row mt-5">
          <div className="col-12 d-flex flex-row justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex flex-row justify-content-center mt-2">
            <h3>Loading last bets...</h3>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    let content = this.renderEmptyList();
    if (this.isEventList()) {
      content = this.renderEventList(
        this.props.contractEvents.eventList
          .filter(entry => entry.event === "logBetSuccess")
          .reverse()
          .slice(0, this.MAX_LIST_SIZE)
      );
    }
    return <Fragment>{content}</Fragment>;
  }
}
