import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeContainer from "./components/Home/HomeContainer";

function AppRouter() {
  return (
    <Router>
      <div className="container-fluid px-md-3 px-lg-5">
        <Switch>
          <Route component={HomeContainer} />
        </Switch>
      </div>
    </Router>
  );
}

export default AppRouter;
