const initialState = {
  contractBalance: 0,
  contractBalanceWei: 0,
  walletBalance: 0,
  walletBalanceWei: 0,
  showPendingTransactionSpinner: 0,
  isWeb3ContainerReady: false,
  contractEvents: {},
  betPrizePrediction: {
    prize: 0,
    payout: 0,
    stakeInEther: 0,
    spinUnder: 0
  }
};

export default function todos(state = initialState, action) {
  switch (action.type) {
    case "IS_WEB3_CONTAINER_READY":
      return Object.assign({}, state, {
        isWeb3ContainerReady: Boolean(action.payload)
      });
    case "PENDING_TRANSACTIONS":
      let showPendingTransactionSpinner = action.payload
        ? state.showPendingTransactionSpinner + 1
        : state.showPendingTransactionSpinner - 1;
      return Object.assign({}, state, {
        showPendingTransactionSpinner:
          showPendingTransactionSpinner < 0 ? 0 : showPendingTransactionSpinner
      });
    case "UPDATE_CONTRACT_BALANCE":
      return Object.assign({}, state, {
        contractBalance: action.payload.contractBalance,
        contractBalanceWei: action.payload.contractBalanceWei
      });
    case "UPDATE_WALLET_BALANCE":
      return Object.assign({}, state, {
        walletBalance: action.payload.walletBalance,
        walletBalanceWei: action.payload.walletBalanceWei
      });
    case "UPDATE_EVENT_LIST":
      let { fromBlock, toBlock, eventList } = action.payload;
      return Object.assign({}, state, {
        contractEvents: {
          fromBlock,
          toBlock,
          eventList
        }
      });
    case "SET_PRIZE_AND_PAYOUT":
      return Object.assign({}, state, {
        betPrizePrediction: {
          prize: action.payload.prize,
          payout: action.payload.payout,
          stakeInEther: action.payload.stakeInEther,
          spinUnder: action.payload.spinUnder
        }
      });
    default:
      return state;
  }
}
