import web3 from "web3";
import { toast } from "react-toastify";

import GambleABI from "./GambleABI.json";
import Actions from "../../actions";

var web3Instance;
var contract;

const web3Options = {
  transactionConfirmationBlocks: 3
};

async function initWeb3Instance() {
  if (!web3Instance) {
    if (window.ethereum) {
      let web3Instance = new web3(window.ethereum, null, web3Options);
      await window.ethereum.enable();
      return web3Instance;
    } else if (window.web3) {
      let web3Instance = new web3(web3.currentProvider, null, web3Options);
      return web3Instance;
    } else {
      return Promise.reject("user must install web3 provider");
    }
  }
}

function initContract(contractAddress, contractABI) {
  if (!contract) {
    if (!contractAddress) throw Error("Missing contract address");
    if (!contractABI) throw Error("Missing contract ABI");
    return new web3Instance.eth.Contract(contractABI, contractAddress);
  }
}

function showSubscriptionSuccess(reduxStore) {
  reduxStore.dispatch(Actions.GambleContract.decreasePendingTransactions());
  reduxStore.dispatch(Actions.GambleContract.refreshBalances());
}

function isEventListUpToDate(reduxStore, event) {
  return (
    reduxStore.getState().GambleContract.contractEvents.eventList[0] ===
    event.transactionHash
  );
}

function subscribeToAccountEvent(eventName, reduxStore) {
  let subscription = contract.events[eventName]();
  subscription
    .on("data", event => {
      if (!isEventListUpToDate(reduxStore, event)) {
        showSubscriptionSuccess(
          reduxStore,
          `${
            eventName === "logDeposit" ? "Deposit" : "Withdraw"
          } was written in the chain! ✌️`
        );
      }
    })
    .on("changed", event => {
      console.log(`changed ${eventName}: ${event}`);
    })
    .on("error", err => {
      toast.error(`Received error from event ${eventName}`);
      console.error(`${eventName}`, err);
    });
}

function subscribeToBetStartedEvent(eventName, reduxStore) {
  let subscription = contract.events[eventName]();
  subscription
    .on("data", event => {
      if (!isEventListUpToDate(reduxStore, event)) {
        showSubscriptionSuccess(reduxStore, "Bet was written in the chain! 👍");
      }
    })
    .on("changed", event => {
      console.log(`changed ${eventName}: ${event}`);
    })
    .on("error", err => {
      toast.error(
        err && err.message
          ? err.message
          : `Error while subscribing to ${eventName}!`
      );
      console.error(`${eventName}`, err);
    });
}

function subscribeToContractEvents(reduxStore) {
  if (!contract || !web3Instance) {
    toast.error("Error while initializing web3 container!");
    console.error("web3 container not well initialized");
  } else {
    subscribeToBetStartedEvent("logBetSuccess", reduxStore);
    subscribeToAccountEvent("logDeposit", reduxStore);
    subscribeToAccountEvent("logWithdraw", reduxStore);
  }
}

async function init(reduxStore, contractAddress, contractABI = GambleABI) {
  try {
    web3Instance = await initWeb3Instance();
    contract = initContract(contractAddress, contractABI);
    subscribeToContractEvents(reduxStore);
    return get();
  } catch (err) {
    toast.error(
      err && err.message ? err.message : "Error while initializing metamask!"
    );
    console.log("user denied metamask", err);
  }
}

function get() {
  return {
    web3Instance,
    web3,
    contract,
    getAccounts: web3Instance ? web3Instance.eth.getAccounts : null
  };
}

export default {
  init,
  get
};
