import React from "react";
import { connect } from "react-redux";

import Actions from "../../actions";
import Home from "./Home";

class HomeContainer extends React.Component {
  render() {
    return (
      <Home
        isWeb3ContainerReady={this.props.isWeb3ContainerReady}
        checkWeb3ContainerReady={this.props.checkWeb3ContainerReady}
      />
    );
  }
}

const mapStateToProps = state => ({
  isWeb3ContainerReady: state.GambleContract.isWeb3ContainerReady
});

const mapDispatchToProps = dispatch => ({
  checkWeb3ContainerReady: () => {
    dispatch(Actions.GambleContract.checkWeb3ContainerReady());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainer);
