import React from "react";
import { connect } from "react-redux";

import Actions from "../../actions";
import BetContract from "./BetContract";

class BetContractContainer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <BetContract
        walletBalance={this.props.walletBalance}
        contractBalance={this.props.contractBalance}
        betOnContract={this.props.betOnContract}
        getStakePayout={this.props.getStakePayout}
        prize={this.props.prize}
        payout={this.props.payout}
      />
    );
  }
}

const mapStateToProps = state => ({
  walletBalance: state.GambleContract.walletBalance,
  contractBalance: state.GambleContract.contractBalance,
  prize: state.GambleContract.betPrizePrediction.prize,
  payout: state.GambleContract.betPrizePrediction.payout
});

const mapDispatchToProps = dispatch => ({
  betOnContract: (stakeInEther, spinUnder) => {
    dispatch(Actions.GambleContract.betOnContract(stakeInEther, spinUnder));
  },
  getStakePayout: (stakeInEther, spinUnder) => {
    dispatch(Actions.GambleContract.getStakePayout(stakeInEther, spinUnder));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetContractContainer);
