import React, { Fragment } from "react";

import DepositWithdrawContractContainer from "../DepositWithdrawContract/DepositWithdrawContractContainer";
import BetContractContainer from "../BetContract/BetContractContainer";
import TransactionsStatusContainer from "../TransactionsStatus/TransactionsStatusContainer";
import ContractFeedContainer from "../ContractFeed/ContractFeedContainer";
import Navbar from "../Navbar/Navbar";
import AppConfig from "../../AppConfig";

export default class Home extends React.Component {
  componentDidMount() {
    if (!this.props.isWeb3ContainerReady) {
      setInterval(
        this.props.checkWeb3ContainerReady,
        AppConfig.REFRESH_TIMER_MS
      );
    }
  }

  renderSpinner() {
    return (
      <div className="container-fluid mt-2 mb-2">
        <div className="row">
          <div className="col-12 d-flex flex-row justify-content-center">
            <div
              className="spinner-border"
              style={{ width: "4rem", height: "4rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderText(text) {
    return (
      <div className="container-fluid mt-2 mb-2">
        <div className="row">
          <div className="col-12 d-flex flex-row justify-content-center">
            <a href="https://metamask.io/">
              <h3 className="text-center">{text}</h3>
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderConnectMetamaskWarning() {
    return (
      <div className="container-fluid mt-5 mb-5">
        <div className="row pt-5">{this.renderSpinner()}</div>
        <div className="row">
          {this.renderText("Connect your Metamask! Click here to download.")}
        </div>
      </div>
    );
  }

  renderHome() {
    return (
      <Fragment>
        <Navbar />
        <TransactionsStatusContainer />
        <hr />
        <div className="row">
          <DepositWithdrawContractContainer />
          <BetContractContainer />
        </div>
        <hr />
        <ContractFeedContainer />
      </Fragment>
    );
  }

  render() {
    if (!this.props.isWeb3ContainerReady) {
      return this.renderConnectMetamaskWarning();
    } else {
      return this.renderHome();
    }
  }
}
