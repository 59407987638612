import React from "react";
import { connect } from "react-redux";

import Actions from "../../actions";
import ContractFeed from "./ContractFeed";

class ContractFeedContainer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <ContractFeed
        walletBalance={this.props.walletBalance}
        contractBalance={this.props.contractBalance}
        getContractEvents={this.props.getContractEvents}
        contractEvents={this.props.contractEvents}
      />
    );
  }
}

const mapStateToProps = state => ({
  walletBalance: state.GambleContract.walletBalance,
  contractBalance: state.GambleContract.contractBalance,
  contractEvents: state.GambleContract.contractEvents
});

const mapDispatchToProps = dispatch => ({
  getContractEvents: from => {
    dispatch(Actions.GambleContract.getContractEvents(from));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractFeedContainer);
