import React from "react";
import { connect } from "react-redux";

import TransactionsStatus from "./TransactionsStatus";

class TransactionsStatusContainer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <TransactionsStatus
        showPendingTransactionSpinner={this.props.showPendingTransactionSpinner}
      />
    );
  }
}

const mapStateToProps = state => ({
  showPendingTransactionSpinner:
    state.GambleContract.showPendingTransactionSpinner
});

export default connect(
  mapStateToProps,
  null
)(TransactionsStatusContainer);
