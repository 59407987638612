import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppRouter from "./AppRouter";
import AppConfig from "./AppConfig";
import Web3Container from "./components/Web3Container/Web3Container";
import reducer from "./reducers";

let middlewares = [thunk];
if (
  AppConfig.ENVIRONMENT.toLowerCase() !== "prod" &&
  AppConfig.ENVIRONMENT.toLowerCase() !== "production"
) {
  middlewares.push(logger);
}
const store = createStore(reducer, applyMiddleware(...middlewares));
Web3Container.init(store, AppConfig.CONTRACT_SETTINGS.ADDRESS);

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AppRouter />
        <ToastContainer />
      </Provider>
    );
  }
}

export default App;
