import React from "react";
import { connect } from "react-redux";

import Actions from "../../actions";
import DepositWithdrawContract from "./DepositWithdrawContract";

class DepositWithdrawContractContainer extends React.Component {
  render() {
    return (
      <DepositWithdrawContract
        walletBalance={this.props.walletBalance}
        walletBalanceWei={this.props.walletBalanceWei}
        contractBalance={this.props.contractBalance}
        contractBalanceWei={this.props.contractBalanceWei}
        depositFromContract={this.props.depositFromContract}
        withdrawFromContract={this.props.withdrawFromContract}
        refreshBalances={this.props.refreshBalances}
      />
    );
  }
}

const mapStateToProps = state => ({
  walletBalance: state.GambleContract.walletBalance,
  walletBalanceWei: state.GambleContract.walletBalanceWei,
  contractBalance: state.GambleContract.contractBalance,
  contractBalanceWei: state.GambleContract.contractBalanceWei
});

const mapDispatchToProps = dispatch => ({
  refreshBalances: () => {
    dispatch(Actions.GambleContract.refreshBalances());
  },
  depositFromContract: amountInEther => {
    dispatch(Actions.GambleContract.depositFromContract(amountInEther));
  },
  withdrawFromContract: (amountInEther, contractBalanceWei) => {
    dispatch(
      Actions.GambleContract.withdrawFromContract(
        amountInEther,
        contractBalanceWei
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepositWithdrawContractContainer);
