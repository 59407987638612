import React, { Fragment } from "react";
import { FaEthereum } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";

import AppConfig from "../../AppConfig";

export default class BetContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinUnder: `51`,
      stakeInEther: `${AppConfig.CONTRACT_SETTINGS.MIN_BET}`
    };
    this.stakeInEtherChanged = this.stakeInEtherChanged.bind(this);
    this.spinUnderChanged = this.spinUnderChanged.bind(this);
    this.renderspinUnderInput = this.renderspinUnderInput.bind(this);
    this.renderBetButton = this.renderBetButton.bind(this);
    this.bet = this.bet.bind(this);
    this.refreshPayout = this.bet.bind(this);
    this.renderPrizeAndPayout = this.renderPrizeAndPayout.bind(this);
    this.refreshPrizeAndPayout = this.refreshPrizeAndPayout.bind(this);
  }

  componentDidMount() {
    this.props.getStakePayout(this.state.stakeInEther, this.state.spinUnder);
  }

  refreshPrizeAndPayout() {
    this.props.getStakePayout(this.state.stakeInEther, this.state.spinUnder);
  }

  stakeInEtherChanged(event) {
    let stakeInEther = event.target.value;
    this.setState({ stakeInEther }, this.refreshPrizeAndPayout);
  }

  spinUnderChanged(event) {
    let spinUnder = event.target.value;
    this.setState({ spinUnder }, this.refreshPrizeAndPayout);
  }

  renderStakeInEtherInput() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            Stake
            <FaEthereum />
          </span>
        </div>
        <input
          type="number"
          name="stakeInEther"
          min={AppConfig.CONTRACT_SETTINGS.MIN_BET}
          max={AppConfig.CONTRACT_SETTINGS.MAX_BET}
          step="0.01"
          className="form-control"
          value={this.state.stakeInEther}
          onChange={this.stakeInEtherChanged}
        />
      </div>
    );
  }

  renderPrize() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            Prize <FaEthereum />
          </span>
        </div>
        <input
          type="text"
          name="prize"
          className="form-control"
          disabled={true}
          value={this.props.prize ? this.props.prize : "N/A"}
        />
      </div>
    );
  }

  renderPayout() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">Payout</span>
        </div>
        <input
          type="text"
          name="payout"
          className="form-control"
          disabled={true}
          value={`${this.props.payout ? this.props.payout : "N/A"}x`}
        />
      </div>
    );
  }

  renderPrizeAndPayout() {
    return (
      <Fragment>
        <div className="col text-center">{this.renderPrize()}</div>
        <div className="col text-center">{this.renderPayout()}</div>
      </Fragment>
    );
  }

  renderspinUnderInput() {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">Spin under</span>
        </div>
        <input
          type="number"
          name="spinUnder"
          min={AppConfig.CONTRACT_SETTINGS.SPIN_MIN}
          max={AppConfig.CONTRACT_SETTINGS.SPIN_MAX}
          step="1"
          className="form-control"
          value={this.state.spinUnder}
          onChange={this.spinUnderChanged}
        />
      </div>
    );
  }

  renderBetButton() {
    return (
      <div className="col-12">
        <button
          style={{ width: "100%" }}
          className="btn btn-danger btn-lg"
          type="button"
          onClick={this.bet}
          disabled={
            this.state.spinUnder < AppConfig.CONTRACT_SETTINGS.SPIN_MIN ||
            this.state.spinUnder > AppConfig.CONTRACT_SETTINGS.SPIN_MAX ||
            this.state.stakeInEther < AppConfig.CONTRACT_SETTINGS.MIN_BET ||
            this.state.stakeInEther > AppConfig.CONTRACT_SETTINGS.MAX_BET
          }
        >
          Spin <IoMdSend />
        </button>
      </div>
    );
  }

  bet() {
    this.props.betOnContract(this.state.stakeInEther, this.state.spinUnder);
  }

  render() {
    return (
      <div className="mt-5 mb-3 my-lg-0 col-12 col-lg-6">
        <form>
          <div className="row">
            <div className="col-6">{this.renderStakeInEtherInput()}</div>
            <div className="col-6">{this.renderspinUnderInput()}</div>
          </div>
          <div className="row mt-2">{this.renderBetButton()}</div>
        </form>
        <div className="row mt-2">{this.renderPrizeAndPayout()}</div>
      </div>
    );
  }
}
