let devConfig = {
  ENVIRONMENT: "development",
  ETHERSCAN_ADDRESS: "https://ropsten.etherscan.io/",
  REFRESH_TIMER_MS: 4000,
  CONTRACT_SETTINGS: {
    ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
    SPIN_MIN: 6,
    SPIN_MAX: 96,
    MIN_BET: 0.05,
    MAX_BET: 1
  }
};

let prodConfig = {
  ENVIRONMENT: "production",
  ETHERSCAN_ADDRESS: "https://etherscan.io/",
  REFRESH_TIMER_MS: 1500,
  CONTRACT_SETTINGS: {
    ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
    SPIN_MIN: 6,
    SPIN_MAX: 96,
    MIN_BET: 0.05,
    MAX_BET: 1
  }
};

function getConfig(env) {
  switch (env.toLowerCase()) {
    case "prod":
    case "production":
      return prodConfig;
    case "development":
    case "dev":
      return devConfig;
    default:
      return devConfig;
  }
}

let AppConfig = getConfig(process.env.REACT_APP_NODE_ENV);
export default AppConfig;
