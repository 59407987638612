import React from "react";

export default class TransactionsStatus extends React.Component {
  renderTransactionsStatus() {
    if (this.props.showPendingTransactionSpinner > 0) {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else {
      return <h4>No pending transactions! 👍</h4>;
    }
  }

  render() {
    return (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 d-flex flex-row justify-content-center">
            {this.renderTransactionsStatus()}
          </div>
        </div>
      </div>
    );
  }
}
