import React from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaTwitter, FaGithub } from "react-icons/fa";

import logo from "./android-chrome-125x125.png";

export default class Navbar extends React.Component {
  renderTwitterLogo() {
    return (
      <a className="nav-link" href="https://twitter.com/wheelspinio">
        <IconContext.Provider value={{ size: "2em", color: "deepskyblue" }}>
          <FaTwitter />
        </IconContext.Provider>
      </a>
    );
  }

  renderGithubLogo() {
    return (
      <a
        className="nav-link"
        href="https://github.com/wheelspinio/wheelspin-io-contracts"
      >
        <IconContext.Provider value={{ size: "2em", color: "black" }}>
          <FaGithub />
        </IconContext.Provider>
      </a>
    );
  }

  renderWheelspinLogo() {
    return (
      <Link className="nounderline" to="/">
        <img src={logo} width="40" height="40" alt="Logo" />
        <span
          style={{
            verticalAlign: "middle",
            color: "black",
            fontSize: "x-large"
          }}
        >
          {" "}
          Wheelspin
        </span>
      </Link>
    );
  }
  render() {
    return (
      <nav className="navbar navbar-light navbar-expand bg-light mt-2">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item navbar-brand">
            {this.renderWheelspinLogo()}
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">{this.renderGithubLogo()}</li>
          <li className="nav-item">{this.renderTwitterLogo()}</li>
        </ul>
      </nav>
    );
  }
}
